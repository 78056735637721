<template>
  <div class="fix">
    <el-card class="box-card login-card">
      <el-form ref="form" :model="form" :rules="rules">
        <div class="fontbox">
          <h2 class="font">YAIMER</h2>
        </div>

        <el-form-item prop="account">
          <span class="inputIcon">
            <Icon :name="'denglu'" />
          </span>
          <el-input v-model="form.account" auto-complete="on" name="username">
          </el-input>
        </el-form-item>
        <el-form-item prop="password">
          <span class="inputIcon">
            <Icon :name="'mimadenglu'" />
          </span>
          <el-input
            v-model="form.password"
            :type="showPwd ? 'text' : 'password'"
            auto-complete="on"
            name="password"
          >
          </el-input>
          <span class="openEye" @click="changeOpenEye">
            <Icon :name="showPwd ? 'eye' : 'eye2'" />
          </span>
        </el-form-item>
        <el-form-item>
          <el-select
            v-model="form.freeTime"
            placeholder="Select login free time"
          >
            <el-option label="a day" value="86400"></el-option>
            <el-option label="a week" value="2592000"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm">login</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import "./../../../assets/css/admin.css";
import Icon from "./../../iconSvg.vue";

export default {
  components: {
    Icon,
  },
  data() {
    return {
      form: {
        account: "",
        password: "",
        freeTime: "",
      },
      rules: {
        account: [
          { required: true, message: "Account is required!", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "The length is between 3 and 20 characters",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "Password is required!", trigger: "blur" },
          {
            min: 3,
            max: 20,
            message: "The length is between 3 and 20 characters",
            trigger: "blur",
          },
        ],
      },
      showPwd: false,
    };
  },
  async beforeCreate() {
    const that = this;
    await this.$axios.get("/adminApi/auth").then(function (resp) {
      if (resp.data.code === 200) {
        that.$notify.success({
          title: resp.data.msg,
        });
        return that.$router.replace("/admin/index");
      }
    });
  },
  methods: {
    submitForm() {
      this.$refs["form"].validate((valid) => {
        if (valid) {
          this.sendForm();
        } else {
          this.$notify.error({
            title: "错误",
            message: "请检查账号或者密码输入格式是否正确！",
          });
          return false;
        }
      });
    },
    changeOpenEye() {
      this.showPwd = !this.showPwd;
    },
    sendForm() {
      let that = this;
      this.$axios
        .get("/adminApi/login", {
          params: this.form,
        })
        .then(function (resp) {
          if (resp.data.code !== 200) {
            that.$notify.error({
              title: resp.data.msg,
              message: resp.data.data,
            });
            that.$refs["form"].resetFields();
            return;
          }
          that.$notify.success({
            message: resp.data.msg,
          });
          localStorage.setItem("Authorization", resp.data.data);
          that.$router.replace("/admin/index");
        });
    },
  },
};
</script>

<style scoped>
.el-card {
  background: #fafeff;
}

.fontbox {
  text-align: center;
  width: 100%;
}
.font {
  display: inline-block;
  color: rgb(130 143 125);
  /* padding: 5px 0; */
  font: italic 2.5em Georgia, serif;
  font-weight: 700;
  padding: 15px 0;
}

.fix {
  width: 100%;
  height: 100%;
  display: flex;
}
.inputIcon {
  display: inline-block;
  z-index: 12;
  margin-left: 12px;
  color: #889aa4;
}
.el-form-item {
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* background: rgba(0, 0, 0, 0.1); */
  border-radius: 5px;
  color: #454545;
}
.el-input {
  max-width: 85%;
  padding: 2px 0;
  /* border: 0; */
}
.el-select {
  width: 100%;
}

.openEye {
  position: absolute;
  cursor: pointer;
  top: 0;
  right: 0;
  line-height: 51px;
  height: 100%;
  margin-right: 12px;
  color: #889aa4;
}

.el-button {
  width: 100%;
}

.box-card {
  margin: auto;
  width: 90%;
  max-width: 468px;
}
</style>